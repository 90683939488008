import getEnv from '@/util/env'

export default {
  name: 'EmailMigrationSummaryDialog',
  props: {
    toogle: {
      type: Boolean,
      default: false,
    },
    domainObject: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'confirm'],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    close () {
      this.$emit('close')
    },

    downloadPdf () {
      const provider = this.domainObject.domain.dnsProvider
      const domainId = this.domainObject.domain.id
      const user = JSON.parse(localStorage.getItem('user'))
      window.open(getEnv('backend_endpoint') + 'api/account/emails/dns-pdf/' + provider + '/' + domainId + '?token=' + user.token, '_blank')
    },
  },
  created () {
  },
}
